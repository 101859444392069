<template>
  <div>
    <VTitle :title="$t('app.ratings', 2)" class="overview-title" />

    <VCard>
      <template #content>
        <div class="flex justify-center items-end mb-2">
          <div class="text-4xl font-bold">
            {{ displayedRating }}
          </div>

          <div class="text-xl">
            /5
          </div>
        </div>

        <div class="flex justify-center mb-2">
          <div class="stars" :style="`--rating: ${rating};`" />
        </div>

        <div class="flex justify-center items-center">
          <div class="space">
            {{ formatAmount(ratingCount ?? 0, 0) }}
          </div>

          <div class="lowercase">
            {{ $t("app.ratings", 2) }}
          </div>
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useDisplay from "@/composables/useDisplay";
// Components
import VCard from "@/components/VCard";
import VTitle from "@/components/VTitle";

export default {
  components: {
    VCard,
    VTitle
  },
  props: {
    rating: {
      type: Number,
      default: 0
    },
    ratingCount: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    // Composables
    const { formatAmount } = useDisplay();

    // Computed
    const displayedRating = computed(() => props.rating.toFixed(1));

    return {
      displayedRating,
      // useDisplay
      formatAmount
    };
  }
};
</script>

<style lang="scss">
:root {
  --star-size: 50px;
  --star-color: #ccc;
  --star-background: #fc0;
}

.stars {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;

  &::before {
    content: "★★★★★";
    letter-spacing: 3px;
    background: linear-gradient(
      90deg,
      var(--star-background) var(--percent),
      var(--star-color) var(--percent)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
