<template>
  <div>
    <VTitle :title="$t('app.statistics', 2)" class="overview-title" />

    <VCard>
      <template #content>
        <div
          v-for="(stat, name, index) in statistics"
          :key="index"
          class="flex justify-between"
          :class="{ 'mb-2': index !== Object.keys(statistics).length - 1 }"
        >
          <div>
            {{ $t(`backend.${name}`) }}
          </div>

          <div class="font-medium">
            {{ stat }}
          </div>
        </div>
      </template>
    </VCard>
  </div>
</template>

<script>
import VCard from "@/components/VCard";
import VTitle from "@/components/VTitle";

export default {
  components: {
    VCard,
    VTitle
  },
  props: {
    statistics: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
